/**
 * Direttiva che costruisce l'intestazione del corpo della barra di ricerca
 */
import { Item, User, SenecaResponse, UserGroup } from "atfcore-commonclasses";

export interface ISearchBarHeaderDirectiveScope extends ng.IScope {
	libraryApplicationData: any;
	filterKey: string;
	currentFilter: any;
	filterName: string;
	isCompetenceFilter: boolean;
	isClusterFilter: boolean;
	isLevelOrFunctionalOrStreamOrAdminTagsOrLangsCompetence: boolean;
}
angular.module('app').directive("searchBarHeader", (LibraryApplicationData, GlobalApplicationData, SearchBarManager) => {
	return {
		restrict: 'E',
		transclude: true,
		scope: {
			unsetLibraryFilter: '=',
			filterName: '@'
		},
		link: link,
		templateUrl: 'app/shared/searchBar/searchBarContent/searchBarHeader/searchBarHeader.html'
	};
	function link($scope: ISearchBarHeaderDirectiveScope, element: JQuery, attrs: ng.IAttributes) {
		// Collegamento all'oggetto principale
		$scope.libraryApplicationData = LibraryApplicationData;

		// Recupero tutte le informazioni sul filtro corrente
		$scope.currentFilter = SearchBarManager.getFilter($scope.filterName);

		// Verifico se il filtro corrente è quello delle competenze (quindi lista di tutti i tech + soft)
		$scope.isCompetenceFilter = $scope.currentFilter && $scope.currentFilter.code && $scope.currentFilter.code === $scope.libraryApplicationData.constants.SOFT_AND_TECH_SKILLS ? true : false;

		// Stessa verifica per il cluster
		$scope.isClusterFilter = $scope.currentFilter && $scope.currentFilter.code && $scope.currentFilter.code === $scope.libraryApplicationData.constants.CLUSTERS ? true : false;

		// Stessa verifica per livelli o aree funzionali o lingue
		$scope.isLevelOrFunctionalOrStreamOrAdminTagsOrLangsCompetence = $scope.currentFilter && $scope.currentFilter.code && ($scope.currentFilter.code === $scope.libraryApplicationData.constants.FUNCTIONAL_AREAS || $scope.currentFilter.code === $scope.libraryApplicationData.constants.LEVELS || $scope.currentFilter.code === $scope.libraryApplicationData.constants.STREAMS || $scope.currentFilter.code === $scope.libraryApplicationData.constants.ADMIN_TAGS || $scope.currentFilter.code === $scope.libraryApplicationData.constants.LANG || $scope.isCompetenceFilter) ? true : false;
	}
});